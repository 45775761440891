<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                <div class="vx-logo flex justify-items-center items-center mb-2 pt-4">
                  <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-height: 25px"/>
                </div>
              <div class="p-4">
                <div class="vx-card__title mb-8 grid justify-items-center">
                  <h4 class="mb-4">{{ $t('primeiro-acesso') }}</h4>
                  <p>{{ $t('por-favor-altere-sua-senha-temporaria') }}</p>
                </div>

                <vs-input
                  type="password"
                  id="password"
                  name="password"
                  v-validate="'required|verify_password'"
                  label-placeholder="Senha"
                  data-vv-as="Senha"
                  v-model="password"
                  class="w-full pb-2 pt-2"
                  ref="password"
                />
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                <vs-input
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="Confirmação Senha"
                  label-placeholder="Confirmar senha"
                  v-model="passwordConfirm"
                  class="w-full pb-2 pt-2"
                />
                <span class="text-danger text-sm">{{ errors.first('password_confirmation') }}</span>

                <div class="flex flex-wrap justify-center sm:flex-row mt-8">
                  <vs-button
                    class="w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto"
                    :disabled="!validateForm"
                    @click="changePassword">{{ $t('alterar') }}</vs-button>
                  <vs-button
                    type="border"
                    @click="goBack"
                    class="w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto">{{ $t('common.back') }}</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import auth from '@/auth/authService'
export default {
  props: {
  },
  data() {
    return {
      password: '',
      passwordConfirm: ''
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && !this.isEmpty(this.password) && !this.isEmpty(this.passwordConfirm)
    }
  },
  methods: {
    changePassword() {
      // Loading
      this.$vs.loading()

      const payload = {
        userDetails: {
          password: this.password,
          passwordConfirm: this.passwordConfirm
        },
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close,
        acl: this.$acl
      }
      this.$store.dispatch('auth/changePasswordAttempt', payload)
    },
    goBack() {
      this.$store.dispatch('auth/logout', {vs: this.$vs})
    }
  }
}
</script>
